import { useMemo } from 'react';
import { useEvent } from '@hooks/event';
import LiveEventActivity from '@components/core/LiveEventActivity';
import LiveAnimation from '@components/core/LiveAnimation';
import { Container, Link, Title, CardsArea } from './styles';

const LiveEvent: React.FC = () => {
  const { liveEvent, activities } = useEvent();

  const eventLink = useMemo(
    () => `${process.env.NEXT_PUBLIC_EVENTS_PLATFORM}${liveEvent?.slug}`,
    [liveEvent],
  );

  if (!liveEvent || !liveEvent.id || !activities || activities.length < 1)
    return null;

  return (
    <Container>
      <Link href={eventLink}>
        <Title>
          <LiveAnimation />
          {liveEvent.name} - Ao vivo
        </Title>
      </Link>
      <CardsArea>
        {activities.map(a => (
          <LiveEventActivity activity={a} key={a.id} eventLink={eventLink} />
        ))}
      </CardsArea>
    </Container>
  );
};

export default LiveEvent;
