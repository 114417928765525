/* eslint-disable jsx-a11y/anchor-is-valid */

import StartseULogo from '../../../../assets/svg/startse-u-logo.svg';
import {
  Container,
  Button,
  BannerTitle,
  Title,
  ContainerCard,
  Border,
} from './styles';

const MarketingSection: React.FC = () => {
  return (
    <ContainerCard>
      <Title>StartSe University</Title>
      <Border>
        <Container>
          <BannerTitle variant="subtitle1">
            Conheça a nossa escola de negócios:
          </BannerTitle>
          <StartseULogo />
          <p>
            A StartSe University prepara e inspira
            <br />
            protagonistas, líderes e empreendedores
            <br />
            para provocarem o futuro agora.
          </p>
          <a
            href={process.env.NEXT_PUBLIC_UNIVERSITY}
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="contained" color="primary">
              Quero saber mais
            </Button>
          </a>
        </Container>
      </Border>
    </ContainerCard>
  );
};

export default MarketingSection;
