import { Box, styled, Typography } from '@material-ui/core';

export const Container = styled('div')(() => ({
  minWidth: '35vw',
  '@media screen and (max-width: 780px)': {
    minWidth: 'auto',
    padding: '0px 10px',
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontFamily: 'Barlow',
  fontSize: '24px',
  fontWeight: 'bold',
  color: '#000000',
  marginBottom: theme.spacing(2),
  paddingLeft: '10px',
}));

export const Border = styled('div')(() => ({
  border: 'solid 20px #fff',
  boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.16)',
  borderRadius: '13px',
  marginBottom: '15px',
  '@media screen and (max-width: 780px)': {
    border: 'solid 10px #fff',
  },
}));

export const ContentPlayer = styled(Box)(() => ({
  display: 'flex',
  backgroundColor: 'black',
  width: '100%',
  height: '380px',
  alignItems: 'center',
  borderRadius: '13px',
  '@media screen and (max-width: 780px)': {
    height: '280px',
  },
}));
