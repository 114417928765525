import React, { useCallback, useEffect, useState } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';
import MarketingSectionCorporate from '@components/pages/home/MarketingSection/Corporate';

import {
  AdManager,
  Footer,
  SliderContent,
  StoriesList,
} from '@components/core';
import {
  ContainerSlider,
  ContainerMarketing,
  EventMarginPage,
} from '@styles/pages/Home';

import { getRandomPains } from '@utils/pains';
import EventCards from '@components/pages/eventos/EventCards';
import dynamic from 'next/dynamic';

import ModalHome from '@components/pages/home/Modal';
import LiveEvent from '@components/core/LiveEvent';
import HomePlayer from '@components/pages/home/HomePlayer';
import MarketingSection from '@components/pages/home/MarketingSection';
import { AllHomeProps } from 'pages';
import { getRecommendations } from '@services/content/recommendations';
import { getContentForCategories } from '@services/content/categories';

import { Box } from '@material-ui/core';
import { allAds } from '@components/core/AdManager';
import { useTracking } from '@hooks/tracking';
import {
  classNameCreatorForCategories,
  classNameCreatorForRecomendations,
  HOME_CLASSES,
} from '@utils/classNameCreator';

const Stories = dynamic(() => import('@components/core/Stories'));
const HeaderForDrawerPages = dynamic(
  () => import('@components/core/HeaderForDrawerPages'),
);
const HomeSkeleton = dynamic(() => import('@components/pages/HomeSkeleton'));

const UnloggedHome: React.FC<AllHomeProps> = ({
  stories,
  liveEvents,
  futureEvents,
  liveSpeakers,
  futureSpeakers,
}) => {
  const vimeoId = '507247663/5025153ff9';
  const perPage = 2;

  const { track } = useTracking();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [elements, setElements] = useState([]);
  const [elementsInPage, setElementsInPage] = useState<JSX.Element[]>([]);

  const [openStories, setOpenStories] = useState<{
    index?: number;
    initial?: number;
    open?: boolean;
  }>({
    index: undefined,
    initial: undefined,
    open: false,
  });

  const handleOpenStories = useCallback(
    (
      event?: React.MouseEvent<HTMLElement, MouseEvent>,
      index?: number,
      initial?: number,
    ) => {
      event?.stopPropagation();
      setOpenStories({ index, initial, open: true });
    },
    [],
  );

  const handleCloseStories = useCallback(
    (event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event?.stopPropagation();
      setOpenStories({ ...openStories, open: false });
    },
    [openStories],
  );

  const getContents = async () => {
    const win = window as any;
    let recommendations = [];
    const userId = win.KM?.i();

    if (userId) {
      recommendations = await getRecommendations(userId);
    }
    const categories = await getContentForCategories(
      getRandomPains()
        ?.map(pain => pain?.slug)
        ?.join(','),
    );

    const recComponents = recommendations?.map(rec => (
      <ContainerSlider key={rec.section}>
        <SliderContent
          WebSerie={false}
          className={classNameCreatorForRecomendations(rec.section)}
          contents={rec.contents}
          title={rec.section}
          onClick={() => {
            track('Clicked in recommendation section', {
              section: rec.section,
            });
          }}
        />
      </ContainerSlider>
    ));

    const catComponents = categories?.map(cat => {
      if (cat?.stories?.length > 0) {
        return (
          <ContainerSlider key={cat.slug}>
            <SliderContent
              WebSerie={false}
              contents={cat?.stories}
              title={cat?.name}
              onClick={() => {
                track('Clicked in category section', {
                  section: cat.name,
                });
              }}
              className={classNameCreatorForCategories(cat.slug)}
            />
          </ContainerSlider>
        );
      }
      return <></>;
    });

    const allItems: JSX.Element[] = [...recComponents, ...catComponents];

    const PublicityElements = [
      <ContainerMarketing className={HOME_CLASSES.marketing}>
        <MarketingSection />
        <HomePlayer vimeoId={vimeoId} />
      </ContainerMarketing>,
      <MarketingSectionCorporate />,
    ];

    PublicityElements.forEach((element, index) =>
      allItems.splice((index + 1) * 3, 0, element),
    );

    allItems.unshift(
      <EventMarginPage className={HOME_CLASSES.events}>
        <EventCards
          liveEvents={liveEvents}
          futureEvents={futureEvents}
          liveSpeakers={liveSpeakers}
          futureSpeakers={futureSpeakers}
        />
      </EventMarginPage>,
    );

    setElements([...allItems, <Footer />]);
    setElementsInPage([...allItems.slice(0, 2)]);
    setLoading(false);
  };

  useEffect(() => {
    getContents();
    // eslint-disable-next-line
  }, []);

  const handleNext = () => {
    setPage(page + 1);
    setElementsInPage(elements.slice(0, (page + 1) * perPage));
  };

  return (
    <>
      <ModalHome />
      <HeaderForDrawerPages
        nothiddenborder={false}
        title="Você está na StartSe!"
        subtitle={
          <>
            Todos os dias, novos conteúdos pra você ficar sempre um passo à
            frente das transformações do mundo.
          </>
        }
      />
      <StoriesList
        onClick={index => handleOpenStories(undefined, undefined, index)}
        contents={stories}
        className={HOME_CLASSES.stories}
      />
      <Box mb="40px">
        <AdManager ad={allAds['platform-home']} />
      </Box>
      <LiveEvent />
      {loading ? (
        <HomeSkeleton />
      ) : (
        <InfiniteScroll
          dataLength={elementsInPage.length}
          hasMore={elementsInPage.length < elements.length}
          next={handleNext}
          loader={<></>}
          scrollableTarget="scrollableDiv"
        >
          {elementsInPage}
        </InfiniteScroll>
      )}
      {openStories.open && (
        <Stories
          stories={stories}
          handleClose={handleCloseStories}
          open={openStories.open}
          initialSlide={openStories.initial}
        />
      )}
    </>
  );
};

export default UnloggedHome;
