import { styled } from '@material-ui/core/styles';

export const Container = styled('div')({
  margin: '20px',
});

export const Link = styled('a')({
  textDecoration: 'none',
});

export const Title = styled('h2')({
  fontFamily: "'Barlow', sans-serif",
  fontSize: '23px',
  fontWeight: 600,
  lineHeight: '44px',
  margin: '0 0 10px',
  color: '#333333',
  '@media screen and (min-width: 1100px)': {
    fontSize: '28px',
    margin: '0 0 20px',
  },
});

export const CardsArea = styled('div')({
  display: 'flex',
  overflow: 'auto',
  '@media screen and (min-width: 1100px)': {
    flexWrap: 'wrap',
  },
});
