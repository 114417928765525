import Player from '@components/core/Player';
import { Box } from '@material-ui/core';

import { Title, Container, ContentPlayer, Border } from './styles';

export interface IHomePlayer {
  vimeoId: string;
}

const HomePlayer: React.FC<IHomePlayer> = ({ vimeoId }) => {
  return (
    <Container>
      <Title>O que é a plataforma StartSe:</Title>
      <Border>
        <ContentPlayer>
          <Box width="100%" maxHeight="380px">
            <Player vimeoId={vimeoId} videoUrl="" />
          </Box>
        </ContentPlayer>
      </Border>
    </Container>
  );
};

export default HomePlayer;
