import { HOME_CLASSES } from '@utils/classNameCreator';
import {
  ContainerCorporate,
  Button,
  BannerTitleCorporate,
  Title,
  ContainerCardCorporate,
  Border,
  CoporateLogo,
} from './styles';

const MarketingSection: React.FC = () => {
  return (
    <ContainerCardCorporate className={HOME_CLASSES.corporate}>
      <Title>StartSe Corporate</Title>
      <Border>
        <ContainerCorporate>
          <CoporateLogo>
            <img src="/icons/startse-logo-white.svg" alt="StartSe Branco" />
            <span>CORPORATE</span>
          </CoporateLogo>
          <BannerTitleCorporate variant="subtitle1">
            Preparando
            <br />
            Organizações
            <br />
            Infinitas
          </BannerTitleCorporate>
          <a
            href={process.env.NEXT_PUBLIC_CORPORATE}
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="contained" color="primary">
              SAIBA MAIS
            </Button>
          </a>
        </ContainerCorporate>
      </Border>
    </ContainerCardCorporate>
  );
};

export default MarketingSection;
