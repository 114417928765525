import { styled } from '@material-ui/core/styles';

export const MenuTopLiveGroupInfo = styled('a')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  backgroundColor: '#fff',
  border: '2px solid #f5f5f5',
  borderRadius: '10px',
  padding: '16px',
  transition: 'all 0.5s ease',
  minWidth: '240px',
  marginRight: '12px',
  marginBottom: '12px',
  width: '100%',
  maxWidth: '600px',
  textDecoration: 'none',
  '@media screen and (min-width: 1100px)': {
    flexDirection: 'row',
    marginRight: '20px',
    marginBottom: '20px',
  },
});

export const MenuTopLiveGroupInfoImg = styled('div')({
  marginRight: '12px',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  '@media screen and (min-width: 1100px)': {
    width: '50%',
    height: '100%',
    justifyContent: 'center',
  },
});

export const MenuTopLiveGroupInfoText = styled('div')({
  width: '100%',
  '@media screen and (min-width: 1100px)': {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
  '& .MuiAvatarGroup-avatar': {
    border: '1px solid #0080ED',
  },
  '& span': {
    fontSize: '13px',
    fontWeight: 500,
    color: '#343434',
    margin: '0',
    paddingTop: '8px',
  },
  '& .right-items': {
    '@media screen and (min-width: 1100px)': {
      marginLeft: '20px',
    },
  },
  '& p': {
    fontSize: '0.75rem',
    color: '#707070',
    margin: '0',
    paddingBottom: '8px',
    paddingTop: '8px',
    '@media screen and (min-width: 1100px)': {
      paddingTop: '0px',
      paddingBottom: '8px',
    },
  },
  '& a': {
    height: '30px',
    width: '212px',
    backgroundColor: '#dd0b00',
    borderRadius: '50px',
    fontSize: '11px',
    fontWeight: 700,
    textTransform: 'uppercase',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& svg': {
    marginRight: '8px',
  },
});

export const PathwayRoom = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '& strong, & span': {
    fontSize: '0.68rem',
    padding: '0px',
    color: '#707070',
  },
  '& span': {
    marginLeft: '2px',

    '&.spanMarginRight': {
      marginRight: '12px',
    },
  },
  '& svg': {
    fontSize: '1rem',
    color: '#707070',
  },
});

export const TitleTalkCard = styled('span')({
  fontSize: '1rem',
  fontFamily: "'Barlow', sans-serif",
  fontWeight: 600,
  textTransform: 'initial',
  paddingBottom: '16px',
  color: '#333',
  '@media screen and (min-width: 1100px)': {
    paddingBottom: '0',
  },
});
