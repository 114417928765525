import {
  styled,
  Typography,
  Button as MaterialButton,
  Box,
} from '@material-ui/core';
import startseUniversityDesktop from '@assets/png/home-university.webp';
import startseCorporate from '@assets/png/home-corporate.webp';

export const Border = styled('div')(() => ({
  border: 'solid 20px #fff',
  boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.16)',
  borderRadius: '13px',
  marginBottom: '15px',
  '@media screen and (max-width: 780px)': {
    border: 'solid 10px #fff',
  },
}));

export const Container = styled('div')(({ theme }) => ({
  width: '100%',
  height: 416,
  display: 'flex',
  backgroundColor: 'black',
  background: `url(${startseUniversityDesktop}) no-repeat bottom right 0`,
  justifyContent: 'space-around',
  alignItems: 'flex-start',
  flexDirection: 'column',
  padding: '20px 40px',
  '& p': {
    color: theme.palette.common.white,
    fontFamily: 'IBM Plex Sans',
    fontSize: 18,
    lineHeight: 2,
    fontWeight: 400,
  },
  '& a': {
    textDecoration: 'none',
  },
  '@media screen and (max-width: 780px)': {
    borderRadius: '14px',
    height: 'auto',
    alignItems: 'left',
    padding: '30px 15px',
    background: `url(${startseUniversityDesktop}) no-repeat -50px bottom`,
    backgroundSize: 'cover',
    '& svg': {
      width: '200px',
      maxWidth: '100%',
      minHeight: '60px',
      marginTop: '20px',
    },
    '& p': {
      lineHeight: 'normal',
      fontSize: '14px',
      width: '60%',
    },
  },
}));

export const ContainerCorporate = styled('div')(() => ({
  width: '100%',
  height: 385,
  display: 'flex',
  backgroundColor: 'black',
  background: `url(${startseCorporate}) no-repeat bottom right 0`,
  justifyContent: 'space-around',
  alignItems: 'flex-start',
  flexDirection: 'column',
  padding: '20px 40px',
  '& a': {
    textDecoration: 'none',
  },
  '@media screen and (max-width: 780px)': {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    padding: '30px 15px',
    borderRadius: '14px',
    justifyContent: 'space-between',
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontFamily: 'Barlow',
  fontSize: 24,
  fontWeight: 'bold',
  color: '#000000',
  marginBottom: theme.spacing(2),
  paddingLeft: '10px',
  '@media screen and (min-width: 1100px)': {
    fontSize: 28,
  },
}));

export const Button = styled(MaterialButton)(() => ({
  borderRadius: 38,
  height: 50,
  width: 250,
  letterSpacing: 0,
  fontSize: 15,
  lineHeight: '28px',
  margin: 'auto',
}));

export const BannerTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontFamily: 'Barlow',
  fontSize: 36,
  lineHeight: '46px',
  fontWeight: 300,
  padding: '4px',
  '@media screen and (max-width: 780px)': {
    fontSize: '16px',
    lineHeight: '25px',
    padding: '0px',
  },
}));

export const PlayerContainer = styled(Typography)(({ theme }) => ({
  // maxWidth: 800,
  margin: theme.spacing(2),
}));

export const ContainerCard = styled(Box)(() => ({
  width: '100%',
  flexDirection: 'column',
  '@media screen and (max-width: 780px)': {
    marginLeft: 0,
    padding: '0px 10px',
    marginTop: '20px',
  },
}));

export const ContainerCardCorporate = styled(Box)(() => ({
  width: '60vw',
  maxWidth: '100%',
  flexDirection: 'column',
  padding: '0px 20px 0px 40px',
  '@media screen and (max-width: 780px)': {
    width: '100%',
    marginLeft: 0,
    padding: '0px 10px',
    marginTop: '20px',
  },
}));

export const BannerTitleCorporate = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontFamily: 'Barlow',
  fontSize: 40,
  lineHeight: '48px',
  fontWeight: 'bold',
}));

export const CoporateLogo = styled('div')(() => ({
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  '& img': {
    height: '28px',
  },
  '& span': {
    marginLeft: '10px',
    fontSize: '22px',
    fontWeight: 600,
    fontFamily: 'Barlow',
  },
  '@media screen and (max-width: 780px)': {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));
