import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import { useMemo } from 'react';
import * as Styled from './styles';

const defaultMax = 100;

export interface ILiveEventActivity {
  activity: any;
  eventLink: string;
}

export interface IHideText {
  text: string;
  maxChar?: number;
}

const hideText = ({ text, maxChar }: IHideText) => {
  const maxCount = maxChar || defaultMax;
  if (text) {
    return (
      text.substring(0, maxCount).trim() +
      (text.length <= maxCount ? '' : '...')
    );
  }
  return '';
};

const LiveEvent: React.FC<ILiveEventActivity> = ({ activity, eventLink }) => {
  const isWorkshop = useMemo(
    () => activity?.type === 'workshop' && activity?.workshopLink,
    [activity],
  );

  return (
    <Styled.MenuTopLiveGroupInfo
      href={isWorkshop ? activity?.workshopLink : eventLink}
      target={isWorkshop ? '_blank' : '_self'}
    >
      <Styled.MenuTopLiveGroupInfoImg>
        <Styled.TitleTalkCard>{activity.title}</Styled.TitleTalkCard>
      </Styled.MenuTopLiveGroupInfoImg>

      <Styled.MenuTopLiveGroupInfoText>
        <AvatarGroup max={4}>
          {(activity.speakerProfile || []).length < 1 && (
            <Avatar alt="Em breve" />
          )}
          {(activity.speakerProfile || []).map(s => {
            if (!s) return null;
            return <Avatar alt={s.name} src={s.imageProfile} key={s.name} />;
          })}
        </AvatarGroup>
        <div className="right-items">
          <p>
            {(activity.speakerProfile || [])
              .map(s => {
                if (!s || !s.name) return '';
                return s.name;
              })
              .join(', ')}
          </p>

          <Styled.PathwayRoom>
            {activity.pathway && activity.pathway.length > 0 && (
              <>
                <strong>Trilhas:</strong>
                <span className="spanMarginRight">
                  {hideText({
                    text: (activity.pathway || []).map(p => p.name).join(', '),
                    maxChar: 55,
                  })}
                </span>
              </>
            )}
            {activity.room && activity.room.name && (
              <>
                <RoomOutlinedIcon />
                <span>
                  {hideText({
                    text: activity.room.name,
                    maxChar: 32,
                  })}
                </span>
              </>
            )}
          </Styled.PathwayRoom>
        </div>
      </Styled.MenuTopLiveGroupInfoText>
    </Styled.MenuTopLiveGroupInfo>
  );
};

export default LiveEvent;
